var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    _vm._l(_vm.cards, function(card, index) {
      return _c(
        "b-col",
        { key: index, attrs: { cols: "12", md: "6", lg: "6" } },
        [
          _c(
            "b-card",
            {
              staticClass: "px-0",
              attrs: { header: card.header, "no-body": "" }
            },
            [_c("EmbedCe", { attrs: { url: card.ceUrl } })],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }