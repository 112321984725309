<template lang="pug">

b-row 
  b-col(
    cols=12, md=6, lg=6, 
    v-for='card, index in cards'
    :key='index'
  )
    b-card.px-0(:header='card.header', no-body)
      EmbedCe(:url='card.ceUrl')


</template>
  
<script>

  export default {
    components: {
      EmbedCe: () => import('@/components/EmbedCe.vue'),
    },
    props: {
      taskAttribute: Object,
    },
    computed: {
      cards () {
        return JSON.parse(
          this.taskAttribute.attributeTypes.DataEntry.Values[0].Value
        ).map(card => {
          const search = (new URLSearchParams({
            filter: JSON.stringify({
              categoryIds: card.attributeCategories,
              attributeTypeIds: card.attributeTypes,
            })
          })).toString()

          card.ceUrl = `TaskAttribute/${this.taskAttribute.TaskAttributeID}/?${search}`
          return card
        })
      },
    }
  }
  </script>